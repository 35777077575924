import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
    <Auth0Provider
      domain={"cason.auth0.com"}
      clientId={"5y2cN5ZtZJEN2H6vq3O6yozCmMgKdYfr"}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "https://api.genth.ink",
        scope: "openid profile email permissions roles"
      }}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Auth0Provider>
);