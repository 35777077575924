// CharacterList.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    IconButton,
    Typography,
    Box,
    Chip,
    CircularProgress
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ChatIcon from '@mui/icons-material/Chat';
import { Character, fetchUserCharacters, deleteCharacter } from '../../services/character_api';
import { useAuth0 } from '@auth0/auth0-react';
import { v4 as uuidv4 } from 'uuid';

const styles = {
    container: {
        padding: '20px',
        maxWidth: '1200px',
        margin: '0 auto'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px'
    },
    tableContainer: {
        marginTop: '20px'
    },
    chip: {
        margin: '2px'
    },
    actions: {
        display: 'flex',
        gap: '8px'
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px'
    }
};

const CharacterList: React.FC = () => {
    const [characters, setCharacters] = useState<Character[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        loadCharacters();
    }, []);

    const loadCharacters = async () => {
        try {
            const token = await getAccessTokenSilently();
            const response = await fetchUserCharacters(token);
            setCharacters(response.items);
        } catch (error) {
            console.error('Error loading characters:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (characterId: string) => {
        if (window.confirm('Are you sure you want to delete this character?')) {
            try {
                const token = await getAccessTokenSilently();
                await deleteCharacter(characterId, token);
                await loadCharacters();
            } catch (error) {
                console.error('Error deleting character:', error);
            }
        }
    };

    const handleEdit = (characterId: string) => {
        navigate(`/characters/${characterId}/edit`);
    };

    const handleChat = (character: Character) => {
        const chatId = uuidv4();
        const slugifiedName = character.name.toLowerCase().replace(/\s+/g, '-');
        navigate(`/chat/${chatId}/?persona=${slugifiedName}`);
    };

    const handleCreateNew = () => {
        navigate('/characters/new');
    };

    if (loading) {
        return (
            <div style={styles.loadingContainer}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <Typography variant="h4">Characters</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateNew}
                >
                    Create New Character
                </Button>
            </div>

            <TableContainer component={Paper} style={styles.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Tags</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {characters.map((character) => (
                            <TableRow key={character.character_id}>
                                <TableCell>{character.name}</TableCell>
                                <TableCell>
                                    {character.description?.slice(0, 100)}
                                    {character.description && character.description.length > 100 ? '...' : ''}
                                </TableCell>
                                <TableCell>
                                    <Box display="flex" flexWrap="wrap" gap={0.5}>
                                        {character.tags?.map((tag) => (
                                            <Chip
                                                key={tag}
                                                label={tag}
                                                size="small"
                                                style={styles.chip}
                                            />
                                        ))}
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    {new Date(character.created_at).toLocaleDateString()}
                                </TableCell>
                                <TableCell>
                                    <div style={styles.actions}>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleChat(character)}
                                            title="Start Chat"
                                        >
                                            <ChatIcon />
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleEdit(character.character_id)}
                                            title="Edit"
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            color="error"
                                            onClick={() => handleDelete(character.character_id)}
                                            title="Delete"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default CharacterList;