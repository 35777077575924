import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import { Box, Card, Typography, Button } from '@mui/material';
import { OutlinedBox } from '../common/Brutalist';
import { withHologram } from '../../styles/effects';
import ChatBubbles from '../ChatComponent/ChatBubbles';
import { Message } from '../ChatComponent/ChatInterfaces';

const Holospan = withHologram((props) => <span {...props} />);

const PageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
  gap: theme.spacing(4),
  padding: theme.spacing(4),
}));

const HeroSection = styled(OutlinedBox)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(4),
  marginBottom: theme.spacing(6),
  paddingBottom: theme.spacing(6),
}));

const GradientTitle = styled(Typography)(({ theme }) => ({
  fontSize: '3.5rem',
  textAlign: 'center',
  background: 'linear-gradient(45deg, #7f5af0, #2cb67d)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem',
  },
}));

const ScenarioTabs = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginTop: theme.spacing(3),
  flexWrap: 'wrap',
  justifyContent: 'center',
}));

interface ScenarioButtonProps {
  active: boolean;
  children: React.ReactNode;
  onClick: () => void;
}

const ScenarioButton = styled(({ active, ...other }: ScenarioButtonProps & { variant?: 'text' | 'outlined' | 'contained' }) => <Button {...other} />)(({ theme, active }) => ({
  backgroundColor: active ? theme.palette.primary.main : 'transparent',
  color: active ? theme.palette.primary.contrastText : theme.palette.text.primary,
  '&:hover': {
    backgroundColor: active ? theme.palette.primary.dark : theme.palette.action.hover,
  },
}));

const ValueSection = styled(OutlinedBox)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(6),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(4),
}));

const FeaturesGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
  gap: theme.spacing(4),
  width: '100%',
  marginTop: theme.spacing(6),
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  height: '100%',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
  },
}));

const ImageContainer = styled(Box)({
  width: '100%',
  aspectRatio: '16/9',
  overflow: 'hidden',
  marginBottom: '1rem',
  borderRadius: '8px',
});

const FeatureImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const features = [
  {
    title: 'Craft Your AI Dream Team',
    description: 'Build your personal council of AI advisors, teachers, and collaborators. From Einstein to Shakespeare, create the perfect team for your goals.',
    image: 'img/a.png',
    action: 'BUILD YOUR TEAM'
  },
  {
    title: 'Dynamic Learning Journeys',
    description: 'Experience personalized learning through interactive conversations. Your AI mentors adapt their teaching style to match your unique way of thinking.',
    image: 'img/b.png',
    action: 'START LEARNING'
  },
  {
    title: 'Visual Storytelling',
    description: 'Watch your ideas transform into visual stories. Our AI brings conversations to life with real-time generated imagery and interactive elements.',
    image: 'img/c.png',
    action: 'EXPLORE VISUALS'
  },
  {
    title: 'Infinite Possibilities',
    description: 'From brainstorming sessions to philosophical debates, create any scenario you can imagine. The only limit is your creativity.',
    image: 'img/d.png',
    action: 'GET INSPIRED'
  }
];

const scenarios = [
  { id: 'learning', title: 'Learn from Einstein' },
  { id: 'philosophy', title: 'Philosophical Debate' },
  { id: 'storytelling', title: 'Create Your World' }
];



const Home: React.FC = () => {
  const [activeScenario, setActiveScenario] = useState(scenarios[0].id);
  const [messages, setMessages] = useState<Message[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const MemoizedChatBubbles = React.memo(ChatBubbles);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Scroll only the chat container instead of the whole page
    if (chatContainerRef.current && messagesEndRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [messages]);

  useEffect(() => {
    const interval = setInterval(() => {
        if (messages.length < 10) {
            setMessages(prevMessages => [...prevMessages, {
                message_id: uuidv4(),
                content: "This is a test",
                role: "user",
                timestamp: Date.now(),
                username: 'User',
                image: "img/noodle.png",
                type: 'text',
            }]);
        }
    }, 5000);

    return () => clearInterval(interval);

  }, []);

  return (
    <PageContainer>
      <HeroSection>
        <Box sx={{ width: '100%', minHeight: '400px', bgcolor: 'background.paper', flexGrow: 1, overflowY: 'auto', maxHeight: '400px' }} ref={chatContainerRef}>
          <MemoizedChatBubbles messages={messages} messagesEndRef={messagesEndRef} />
        </Box>
        <ScenarioTabs>
          {scenarios.map((scenario) => (
            <ScenarioButton
              key={scenario.id}
              active={activeScenario === scenario.id}
              onClick={() => setActiveScenario(scenario.id)}
              variant="contained"
            >
              {scenario.title}
            </ScenarioButton>
          ))}
        </ScenarioTabs>
      </HeroSection>

      <ValueSection>
        <Typography variant="h3" gutterBottom align="center" sx={{ fontWeight: 'bold', mb: 3 }}>
          Your Personal AI Universe
        </Typography>
        <Typography align="justify" sx={{ maxWidth: '800px', margin: '0 auto', color: 'text.secondary' }}>
          Imagine having access to the greatest minds in history, creative collaborators, and expert mentors - all powered by advanced AI. Create your own constellation of AI characters that think, teach, and evolve with you. Whether you're seeking knowledge, exploring ideas, or building worlds, we're transforming how humans interact with AI.
        </Typography>

        <FeaturesGrid>
          {features.map((feature, index) => (
            <FeatureCard key={index}>
              <ImageContainer>
                <FeatureImage src={feature.image} alt={feature.title} />
              </ImageContainer>
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                {feature.title}
              </Typography>
              <Typography variant="body1" sx={{ flex: 1 }}>
                {feature.description}
              </Typography>
              <Typography variant="button" sx={{ opacity: 0.7 }}>
                {feature.action}
              </Typography>
            </FeatureCard>
          ))}
        </FeaturesGrid>
      </ValueSection>
    </PageContainer>
  );
};

export default Home;