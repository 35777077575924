import React, { createContext, useState, useEffect, useContext } from 'react';
import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import Header from './components/common/Header';
import SideBar from './components/common/SideBar';
import MainWindow from './components/MainWindow';
import { ColorModeProvider } from './ColorModeContext';
import {
  connectSocket,
  sendMessage,
  subscribe,
  unsubscribe,
  disconnectSocket,
  WebSocketContextType
} from './services/socketService';
import { useAuth0 } from '@auth0/auth0-react';
import { Route, Routes } from 'react-router-dom';
import EditProfile from './components/Profile/EditProfile';
import Profile from './components/Profile/Profile';
import ChatComponent from './components/ChatComponent/ChatComponent';
import CharacterEditor from './components/Character/CharacterEditor';
import CharacterList from './components/Character/CharacterList';
import AuthedHome from './components/Home/AuthedHome';
import Home from './components/Home/Home';

const WebSocketContext = createContext<WebSocketContextType | null>(null);

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};

const App: React.FC = () => {
  const isTabletOrGreater = useMediaQuery('(min-width: 768px)');
  const [sidebarOpen, setSidebarOpen] = useState(isTabletOrGreater);
  const [sidebarWidth, setSidebarWidth] = useState<number>(() => {
    const savedWidth = localStorage.getItem('sidebarWidth');
    return savedWidth ? parseInt(savedWidth, 10) : 250;
  });

  const { isAuthenticated, getAccessTokenSilently, isLoading, user } = useAuth0();
  const [response, setResponse] = useState<string>('');

  useEffect(() => {
    setSidebarOpen(isTabletOrGreater);
  }, [isTabletOrGreater]);

  useEffect(() => {
    if (isAuthenticated) {
      connectSocket(getAccessTokenSilently);

      subscribe('response', (data: string) => {
        setResponse(data);
      });

      return () => {
        unsubscribe('response');
        disconnectSocket();
      };
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // const handleResize = (newWidth: number) => {
  //   setSidebarWidth(newWidth);
  //   localStorage.setItem('sidebarWidth', newWidth.toString());
  // };

  useEffect(() => {
    setSidebarWidth(0);
  }, [])
  
  if (isLoading) {
    return (
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ColorModeProvider>
      <WebSocketContext.Provider value={{ sendMessage, subscribe, unsubscribe }}>
        <Box display="flex" height="100vh" overflow="hidden">
          {/* {sidebarOpen && (
            <SideBar
              toggleSidebar={toggleSidebar}
              width={sidebarWidth}
              onResize={handleResize}
            />
          )} */}
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            overflow="hidden"
            sx={{ 
              width: sidebarOpen ? `calc(100% - ${sidebarWidth}px)` : '100%', 
              transition: 'width 0.3s ease',
              marginBottom: 0,
              paddingBotom: 0
            }}
          >
            <Header
              sidebarOpen={sidebarOpen}
              sidebarWidth={sidebarWidth}
              toggleSidebar={toggleSidebar}
            />
            <Box display="flex" flexGrow={1} overflow="auto" data-testid="boxxxy" sx={{mt:"64px", width:"100%"}}>
              {/* <MainWindow data-testid="main-window" /> */}
              <Routes>
                <Route
                  path="/me/edit"
                  element={<EditProfile />}
                />
                <Route
                  path="/me"
                  element={<Profile />}
                />
                <Route
                  path="/chat/:chat_id"
                  element={<ChatComponent />}
                />
                <Route
                  path="/characters/new"
                  element={<CharacterEditor />}
                />
                <Route
                  path="/characters/:characterId/edit"
                  element={<CharacterEditor />}
                />
                <Route path="/explore" element={<CharacterList />} />
                <Route
                  path="/"
                  element={user ? <AuthedHome /> : <Home />}
                />
              </Routes>
            </Box>
          </Box>
        </Box>
      </WebSocketContext.Provider>
    </ColorModeProvider>
  );
};

export default App;