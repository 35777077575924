class SpeechService {
    private isSpeaking: boolean = false;
    private isTTSEnabled: boolean = true;
    private selectedVoice: SpeechSynthesisVoice | null = null;
    private listeners: Set<() => void> = new Set();
    private eventListeners: { [key: string]: Set<() => void> } = {
        'speechstart': new Set(),
        'speechend': new Set(),
        'speechpause': new Set(),
        'speechresume': new Set(),
        'wordboundary': new Set(),
    };
    private currentUtterance: SpeechSynthesisUtterance | null = null;
    private debug: boolean = false;

    constructor() {
        this.loadVoices();
        if (typeof window !== 'undefined') {
            window.speechSynthesis.onvoiceschanged = this.loadVoices;
        }
    }

    private loadVoices = () => {
        const voices = window.speechSynthesis.getVoices();
        if (voices.length > 0 && !this.selectedVoice) {
            this.selectedVoice = voices.find((voice) => voice.lang === 'en-GB') || voices[0];
        }
        this.notifyListeners();
    };

    private notifyListeners() {
        this.listeners.forEach((listener) => listener());
    }

    addListener(listener: () => void) {
        this.listeners.add(listener);
    }

    removeListener(listener: () => void) {
        this.listeners.delete(listener);
    }

    addEventListener(event: 'speechstart' | 'speechend' | 'speechpause' | 'speechresume' | 'wordboundary', listener: () => void) {
        this.eventListeners[event].add(listener);
    }

    removeEventListener(event: 'speechstart' | 'speechend' | 'speechpause' | 'speechresume' | 'wordboundary', listener: () => void) {
        this.eventListeners[event].delete(listener);
    }

    private triggerEvent(event: 'speechstart' | 'speechend' | 'speechpause' | 'speechresume' | 'wordboundary') {
        this.eventListeners[event].forEach((listener) => listener());
    }

    speak(text: string, oncomplete?: () => void) {
        if (!this.isTTSEnabled || !this.selectedVoice) {
            return;
        }
        
        this.isSpeaking = true;
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.voice = this.selectedVoice;
        
        utterance.onstart = () => {
            if (this.debug) console.log('Speech started');
            this.triggerEvent('speechstart');
        };
        utterance.onend = () => {
            if (this.debug) console.log('Speech ended');
            this.isSpeaking = false;
            this.triggerEvent('speechend');
            if (oncomplete) oncomplete();  // Notify when speaking is done
        };
        utterance.onboundary = (event) => {
            if (this.debug) console.log('Word boundary:', event);
            this.triggerEvent('wordboundary');
        };
        utterance.onerror = (event) => console.error('Speech error:', event);
        
        this.currentUtterance = utterance;
        window.speechSynthesis.speak(utterance);
        this.notifyListeners();
    }

    stopSpeaking() {
        window.speechSynthesis.cancel();
        this.isSpeaking = false;
        this.currentUtterance = null;
        this.triggerEvent('speechend');
        this.notifyListeners();
    }

    toggleTTS() {
        this.isTTSEnabled = !this.isTTSEnabled;
        if (!this.isTTSEnabled) {
            this.stopSpeaking();
        }
        this.notifyListeners();
    }

    setSelectedVoice(voice: SpeechSynthesisVoice) {
        this.selectedVoice = voice;
        this.notifyListeners();
    }

    getState() {
        return {
            isTTSEnabled: this.isTTSEnabled,
            isSpeaking: this.isSpeaking,
            selectedVoice: this.selectedVoice,
            voices: window.speechSynthesis.getVoices(),
        };
    }
}

export const speechService = new SpeechService();