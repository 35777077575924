import React, { useState } from 'react';
import { Box, TextField, IconButton, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

interface ChatInputProps {
  onSendMessage: (message: string) => void;
  startConversation: () => void;
  hasMessages: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage, startConversation, hasMessages }) => {
  const [inputValue, setInputValue] = useState('');

  const handleSendMessage = () => {
    if (inputValue.trim()) {
      onSendMessage(inputValue);
      setInputValue('');
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '900px', width: '100%', justifyContent: "center"}}>
      {hasMessages ? (
        <>
          <TextField
            multiline
            maxRows={4}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={handleKeyPress}
            variant="outlined"
            fullWidth
            placeholder="Type your message..."
            sx={{ flexGrow: 1, marginRight: 1 }}
          />
          <IconButton color="primary" onClick={handleSendMessage}>
            <SendIcon />
          </IconButton>
        </>
      ) : (
        <Button color="primary" variant="contained" onClick={startConversation}>
          Start Conversation
        </Button>
      )}
    </Box>
  );
};

export default ChatInput;