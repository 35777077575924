import React, { useState, useRef, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  useTheme,
  Popper,
  ClickAwayListener,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from '../common/LoginButton';
import ProfileDetails from '../Profile/ProfileDetails';
import { getOrCreateUser } from '../../services/api';
import { withHologram } from '../../styles/effects';

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'sidebarOpen' && prop !== 'sidebarWidth'
})<{ sidebarOpen: boolean; sidebarWidth: number }>(({ theme, sidebarOpen, sidebarWidth }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#111' : '#f0f0f0',
  borderBottom: `1px solid ${theme.palette.mode === 'dark' ? '#333' : '#ccc'}`,
  boxShadow: 'none',
  zIndex: theme.zIndex.drawer + 1,
  width: `calc(100% - ${sidebarOpen ? sidebarWidth : 0}px)`,
  transition: 'width 0.3s ease',
}));

const LogoLink = styled(Link)(({ theme }) => ({
  fontFamily: 'monospace',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  textDecoration: 'none',
  color: theme.palette.mode === 'dark' ? '#fff' : '#000',
}));

const NavLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean }>(({ theme, isActive }) => ({
  color: theme.palette.mode === 'dark' ? '#999' : '#666',
  textDecoration: 'none',
  fontFamily: 'monospace',
  fontSize: '0.9rem',
  padding: '4px 8px',
  position: 'relative',
  marginLeft: '16px',
  transition: 'color 0.3s ease',
  ...(isActive && {
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    fontWeight: 'bold',
    '&::after': {
      transform: 'scaleX(1)',
      opacity: 1,
    },
  }),
  '&:hover::after': {
    transform: 'scaleX(1)',
    opacity: 1,
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -2,
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: theme.palette.primary.main, // Use theme's primary color
    transform: 'scaleX(0)',
    opacity: 0,
    transition: 'transform 0.3s ease, opacity 0.3s ease',
  },
}));

const ProfilePopper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
}));

const NavSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const DesktopNavLinks = styled(Box)(({ theme }) => ({
  display: 'none',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const Holospan = withHologram((props) => <span {...props} />);

interface HeaderProps {
  sidebarOpen: boolean;
  sidebarWidth: number;
  toggleSidebar: () => void;
}

const Header: React.FC<HeaderProps> = ({ sidebarOpen, sidebarWidth, toggleSidebar }) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  const [profileOpen, setProfileOpen] = useState(false);
  const [server_user, setServerUser] = useState({} as any);
  const accountButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const getCreateUser = async () => {
      if (isAuthenticated && user && !isLoading) {
        const token = await getAccessTokenSilently();
        const dude = await getOrCreateUser({
          "sub": user.sub,
          "name": user.name,
          "email": user.email,
          "picture": user.picture,
          "nickname": user.nickname,
          "given_name": user.given_name,
          "family_name": user.family_name,
          "email_verified": user.email_verified,
          "updated_at": user.updated_at
        }, token);
        setServerUser(dude);
      }
    }
    getCreateUser();
  }, [isAuthenticated, user, isLoading, getAccessTokenSilently]);

  const handleToggleProfile = () => {
    setProfileOpen((prevOpen) => !prevOpen);
  };

  const handleCloseProfile = (event: MouseEvent | TouchEvent) => {
    if (accountButtonRef.current && accountButtonRef.current.contains(event.target as Node)) {
      return;
    }
    setProfileOpen(false);
  };

  const handleViewProfile = () => {
    setProfileOpen(false);
    navigate('/me');
  };

  if (isLoading) {
    return null;
  }

  const isCurrentPath = (path: string) => location.pathname === path;

  return (
    <StyledAppBar position="fixed" sidebarOpen={sidebarOpen} sidebarWidth={sidebarWidth}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <LogoLink to="/">
            GEN<Holospan>THINK</Holospan>
          </LogoLink>
        </Box>
        <NavSection>
          <DesktopNavLinks>
            <NavLink to="/explore" isActive={isCurrentPath('/explore')}>
              <Typography variant="body2" component="span" sx={{ marginRight: 1, color: 'inherit' }}>
                01
              </Typography>
              EXPLORE
            </NavLink>
            {isAuthenticated && (
              <NavLink to="/me" isActive={isCurrentPath('/me')}>
                <Typography variant="body2" component="span" sx={{ marginRight: 1, color: 'inherit' }}>
                  02
                </Typography>
                ME
              </NavLink>
            )}
            {isAuthenticated && (
              <NavLink to="/create" isActive={isCurrentPath('/create')}>
                <Typography variant="body2" component="span" sx={{ marginRight: 1, color: 'inherit' }}>
                  03
                </Typography>
                CREATE
              </NavLink>
            )}
          </DesktopNavLinks>
          {isAuthenticated && (
            <ClickAwayListener onClickAway={handleCloseProfile}>
              <Box sx={{ marginLeft: 2 }}>
                <IconButton
                  color="inherit"
                  ref={accountButtonRef}
                  onClick={handleToggleProfile}
                >
                  <Avatar src={server_user?.picture} alt={server_user?.name} />
                </IconButton>
                <Popper
                  open={profileOpen}
                  anchorEl={accountButtonRef.current}
                  placement="bottom-end"
                  modifiers={[
                    {
                      name: 'preventOverflow',
                      options: {
                        boundary: 'viewport',
                      },
                    },
                  ]}
                >
                  <ProfilePopper>
                    <ProfileDetails />
                    <List>
                      <ListItem disablePadding>
                        <ListItemButton onClick={handleViewProfile}>
                          <ListItemText primary="View Profile" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                          <ListItemText primary="Logout" />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </ProfilePopper>
                </Popper>
              </Box>
            </ClickAwayListener>
          )}
          {!isAuthenticated && (
            <LoginButton sx={{ marginLeft: 2 }} />
          )}
        </NavSection>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;