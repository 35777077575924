import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { useColorMode } from '../../ColorModeContext';
import MarkdownRenderer from '../common/MarkdownRenderer';

interface TextBubbleProps {
  message_id: string;
  username: string;
  image: string;
  content: string;
  timestamp: number;
  role: string;
}

const TextBubble: React.FC<TextBubbleProps> = ({ message_id, username, image, content, timestamp, role }) => {
  const { mode } = useColorMode();
  const styles = {
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingY: 2,
    },
    innerContainer: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: 1200,
      width: '100%',
      paddingX: 2,
    },
    avatar: {
      marginRight: 2,
    },
    messageContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      backgroundColor: role === "user" ? (mode === 'light' ? '#ffffff' : '#1d1d1d') : 'transparent',
      padding: role === "user" ? 2 : 0,
      borderRadius: role === "user" ? 2 : 0,
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 1,
    },
    username: {
      fontWeight: 'bold',
      marginRight: 1,
      fontSize: "1.2rem"
    },
    timestamp: {
      color: 'textSecondary',
    },
  };

  return (
    <Box sx={styles.container} key={message_id}>
      <Box sx={styles.innerContainer}>
        <Avatar alt={username} src={image} sx={styles.avatar} />  
        <Box sx={styles.messageContainer}>
          <Box sx={styles.headerContainer}>
            <Typography variant="subtitle1" sx={styles.username}>
              {username || role}
            </Typography>
            <Typography variant="caption" sx={styles.timestamp}>
              {timestamp}
            </Typography>
          </Box>
          <MarkdownRenderer content={content} />
        </Box>
      </Box>
    </Box>
  );
};

export default TextBubble;