import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
import { useColorMode } from '../../ColorModeContext';
import { useWebSocket } from '../../App';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { getConversation } from '../../services/api';
import { v4 as uuidv4 } from 'uuid';
import { speechService } from '../../services/SpeechService';
import { Message, Persona } from './ChatInterfaces';
import LatestImageDisplay from './LatestImageDisplay';
import ChatInput from './ChatInput';
import LoadingSpinner from './LoadingSpinner';
import ChatBubbles from './ChatBubbles';
import PersonaSelector2 from '../Character/CharacterSelector';
import { Character } from '../../services/character_api';

const MemoizedLoadingSpinner = React.memo(LoadingSpinner);
const MemoizedChatInput = React.memo(ChatInput);
const MemoizedPersonaSelector = React.memo(PersonaSelector2);
const MemoizedLatestImageDisplay = React.memo(LatestImageDisplay);
const MemoizedChatBubbles = React.memo(ChatBubbles);

const ChatComponent: React.FC = () => {
  const { project_id, chat_id: paramChatId } = useParams<{ project_id: string; chat_id: string }>();
  const [chat_id, setChatId] = useState<string | null>(paramChatId || uuidv4());
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPersona, setSelectedPersona] = useState<Character | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { mode } = useColorMode();
  const { sendMessage, subscribe, unsubscribe } = useWebSocket();
  const { user, getAccessTokenSilently } = useAuth0();
  const styles = getStyles(mode);

  useEffect(() => {
    const fetchChat = async () => {
      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();
        if (paramChatId) {
          const chat = await getConversation(project_id!, chat_id!, token);
          setMessages(chat.messages);
        }
      } catch (error) {
        console.error('Error fetching chat:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchChat();
  }, [paramChatId, chat_id, project_id, getAccessTokenSilently]);

  useEffect(() => {
    subscribe('message', (payload: any) => {
      setMessages(prevMessages => [...prevMessages, { type: "text", ...payload }]);
    });

    return () => {
      unsubscribe('message');
    };
  }, [subscribe, unsubscribe, chat_id]);

  const handlePersonaChange = useCallback((persona: Character | null) => {
    setSelectedPersona(persona);
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSendMessage = (inputValue: string) => {
    if (inputValue.trim()) {
      speechService.stopSpeaking();
      setMessages(prevMessages => [...prevMessages, {
        message_id: uuidv4(),
        content: inputValue,
        role: "user",
        timestamp: Date.now(),
        username: user?.name || 'User',
        image: user?.picture || "img/noodle.png",
        type: 'text',
      }]);

      sendMessage('message', {
        content: inputValue,
        chat_id: chat_id,
        requested_bot_id: selectedPersona?.character_id
      });
    }
  };

  if (isLoading) {
    return <MemoizedLoadingSpinner styles={styles} />;
  }

  return (
    <Box sx={styles.container} data-testid="chat-component">
      <Box sx={styles.content}>
        <Box sx={styles.imageWrapper}>
          <MemoizedLatestImageDisplay placeholderImage={selectedPersona?.avatar_url || ""}/>
        </Box>
        <Box sx={styles.chatWrapper}>
          <Box sx={{paddingRight: 2, paddingTop: "8px"}}>
            <MemoizedPersonaSelector onSelect={handlePersonaChange} />
          </Box>
          <MemoizedChatBubbles messages={messages} messagesEndRef={messagesEndRef} />
          <Box sx={styles.inputContainer}>
            <MemoizedChatInput
              onSendMessage={handleSendMessage}
              startConversation={() => sendMessage('message', {
                content: '',
                chat_id: chat_id,
                requested_bot_id: selectedPersona?.character_id,
                is_init_message: true,
              })}
              hasMessages={messages.length > 0}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatComponent;

function getStyles(mode: string) {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      backgroundColor: mode === 'light' ? '#f0f0f0' : '#121212',
    },
    content: {
      display: 'flex',
      flexDirection: { xs: 'column', md: 'row' },
      flex: 1,
      overflow: 'hidden',
    },
    imageWrapper: {
      flex: { xs: '0 0 35%', md: '0 0 40%' }, // 35% height on small screens, 40% width on larger screens
      padding: 2,
      position: { xs: 'sticky', md: 'static' },
      top: 0,
      zIndex: 1,
      backgroundColor: mode === 'light' ? '#f0f0f0' : '#121212',
    },
    chatWrapper: {
      flex: { xs: '1 1 65%', md: '0 0 60%' }, // 65% height on small screens, 60% width on larger screens
      display: 'flex',
      flexDirection: 'column',
      overflowY: { xs: 'auto', md: 'hidden' },
      padding: 0,
      paddingTop: "10px"
    },
    inputContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 2,
      borderTop: '1px solid',
      
      borderColor: mode === 'light' ? '#ddd' : '#333',
      backgroundColor: mode === 'light' ? '#ffffff' : '#1d1d1d',
      borderTopLeftRadius: "15px" 
    },
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: mode === 'light' ? '#ffffff' : '#1d1d1d',
    },
  };

  return styles;
}