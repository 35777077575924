import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Avatar, Box, Chip, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { Character, fetchAllCharacters, fetchPublicCharacters } from '../../services/character_api';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';

interface PersonaSelectorProps {
  onSelect: (character: Character) => void;
}

const styles = {
  description: {
    fontSize: '0.875rem',
    color: 'text.secondary',
    textAlign: 'left',
  },
  tagContainer: {
    display: 'flex', 
    flexWrap: 'wrap',
    gap: 0.5,
    marginTop: 1,
  },
  menuItem: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '8px 16px',
    gap: 2,
  },
  avatar: {
    width: 108,
    height: 108,
    borderRadius: '16px',
    borderBottomLeftRadius: 0,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
    flex: 1,
  },
  selectedValue: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 2,
    width: '100%',
    padding: '8px 0',
  },
  selectedTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
    flex: 1,
  },
  select: {
    '& .MuiSelect-select': {
      padding: '0 32px 0 10px',
    }
  }
};

export default function PersonaSelector({ onSelect }: PersonaSelectorProps) {
  const [selectedCharacterId, setSelectedCharacterId] = useState<string | null>(null);
  const [characters, setCharacters] = useState<Character[]>([]);
  const location = useLocation();
  const isFirstLoad = useRef(true);
  const { getAccessTokenSilently } = useAuth0();

  const loadCharacters = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetchAllCharacters(token);
      setCharacters(response.items);

      if (isFirstLoad.current) {
        const queryParams = new URLSearchParams(location.search);
        const characterId = queryParams.get('character');

        if (characterId) {
          const character = response.items.find(c => c.character_id === characterId);
          if (character) {
            setSelectedCharacterId(characterId);
            onSelect(character);
          }
        } else if (response.items.length > 0 && !selectedCharacterId) {
          setSelectedCharacterId(response.items[0].character_id);
          onSelect(response.items[0]);
        }

        isFirstLoad.current = false;
      }
    } catch (error) {
      console.error('Error fetching characters:', error);
    }
  }, [onSelect, selectedCharacterId, location.search]);

  useEffect(() => {
    loadCharacters();
  }, [loadCharacters]);

  const handleSelect = (event: SelectChangeEvent<string>) => {
    const id = event.target.value as string;
    const character = characters.find(c => c.character_id === id);
    if (character) {
      setSelectedCharacterId(id);
      onSelect(character);
    }
  };

  const selectedCharacter = characters.find(c => c.character_id === selectedCharacterId);

  return (
    <Select
      value={selectedCharacterId || ''}
      onChange={handleSelect}
      displayEmpty
      fullWidth
      sx={styles.select}
      renderValue={() => {
        if (!selectedCharacter) return null;
        return (
          <Box sx={styles.selectedValue}>
            <Avatar
              alt={selectedCharacter.name}
              src={selectedCharacter.avatar_url}
              variant="square"
              sx={styles.avatar}
            />
            <Box sx={styles.selectedTextContainer}>
              <Typography variant="h4" sx={{textAlign: "left"}}>{selectedCharacter.name}</Typography>
              <Typography variant="body2" sx={styles.description}>
                {selectedCharacter.description}
              </Typography>
              <Box sx={styles.tagContainer}>
                {selectedCharacter.tags?.map((tag: string) => (
                  <Chip key={tag} label={tag} size="small" />
                ))}
              </Box>
            </Box>
          </Box>
        );
      }}
    >
      {characters.map((character) => (
        <MenuItem key={character.character_id} value={character.character_id} sx={styles.menuItem}>
          <Avatar
            alt={character.name}
            src={character.avatar_url}
            variant="square"
            sx={styles.avatar}
          />
          <Box sx={styles.textContainer}>
            <Typography variant="h4" sx={{textAlign: "left"}}>{character.name}</Typography>
            <Typography variant="body2" sx={styles.description}>
              {character.description}
            </Typography>
            <Box sx={styles.tagContainer}>
              {character.tags?.map((tag: string) => (
                <Chip key={tag} label={tag} size="small" />
              ))}
            </Box>
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
}