import axios from 'axios';

export interface Character {
  character_id: string;
  name: string;
  description?: string;
  first_message?: string;
  knowledge?: string;
  knowledge_urls?: string;
  voice?: string;
  voice_service?: string;
  bbox_x?: number;
  bbox_y?: number;
  bbox_width?: number;
  bbox_height?: number;
  system_prompt?: string;
  physical_description?: string;
  avatar_filename?: string;
  avatar_url?: string;
  tags?: string[];
  is_nsfw?: boolean;
  is_public?: boolean;
  creator_user_id: string;
  created_at: string;
  updated_at: string;
}

export interface CharacterUpdate {
  name?: string;
  description?: string;
  first_message?: string;
  knowledge?: string;
  knowledge_urls?: string;
  voice?: string;
  voice_service?: string;
  bbox_x?: number;
  bbox_y?: number;
  bbox_width?: number;
  bbox_height?: number;
  system_prompt?: string;
  physical_description?: string;
  avatar_filename?: string;
  avatar_url?: string;
  tags?: string[];
  is_nsfw?: boolean;
  is_public?: boolean;
}

export interface PaginatedResponse<T> {
  items: T[];
  last_evaluated_key?: Record<string, any>;
}

const API_URL = process.env.REACT_APP_API_URL;

export const fetchUserCharacters = async (
  token: string,
  lastKey?: string
): Promise<PaginatedResponse<Character>> => {
  try {
    const response = await axios.get(`${API_URL}/characters`, {
      params: { last_key: lastKey },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user characters:', error);
    throw error;
  }
};

export const fetchPublicCharacters = async (
  lastKey?: string
): Promise<PaginatedResponse<Character>> => {
  try {
    const response = await axios.get(`${API_URL}/characters/public`, {
      params: { last_key: lastKey }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching public characters:', error);
    throw error;
  }
};

export const fetchAllCharacters = async (
  token: string,
  lastKey?: string
): Promise<PaginatedResponse<Character>> => {
  try {
    const response = await axios.get(`${API_URL}/characters/all`, {
      params: { last_key: lastKey },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching all characters:', error);
    throw error;
  }
};

export const fetchCharacter = async (
  characterId: string, 
  token: string
): Promise<Character> => {
  try {
    const response = await axios.get(`${API_URL}/characters/${characterId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching character ${characterId}:`, error);
    throw error;
  }
};

export const createCharacter = async (
  character: CharacterUpdate, 
  token: string
): Promise<Character> => {
  try {
    const response = await axios.post(`${API_URL}/characters`, character, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating character:', error);
    throw error;
  }
};

export const updateCharacter = async (
  characterId: string, 
  character: CharacterUpdate, 
  token: string
): Promise<Character> => {
  try {
    const response = await axios.put(`${API_URL}/characters/${characterId}`, character, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error updating character ${characterId}:`, error);
    throw error;
  }
};

export const deleteCharacter = async (
  characterId: string, 
  token: string
): Promise<void> => {
  try {
    await axios.delete(`${API_URL}/characters/${characterId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    console.error(`Error deleting character ${characterId}:`, error);
    throw error;
  }
};

// Helper function to handle pagination
export const fetchAllPages = async (
  fetchFunction: (lastKey?: string) => Promise<PaginatedResponse<Character>>,
  maxPages = Infinity
): Promise<Character[]> => {
  let allItems: Character[] = [];
  let lastKey: Record<string, any> | undefined;
  let pageCount = 0;

  do {
    const response = await fetchFunction(lastKey ? JSON.stringify(lastKey) : undefined);
    allItems = [...allItems, ...response.items];
    lastKey = response.last_evaluated_key;
    pageCount++;
  } while (lastKey && pageCount < maxPages);

  return allItems;
};