import React from "react";
import { LogoutOptions, useAuth0 } from "@auth0/auth0-react";
import { Avatar, Typography, Box, CircularProgress, Paper, Button } from "@mui/material";

const ProfileDetails = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  if (!isAuthenticated || !user) {
    return null;
  }

  return (
    <Paper elevation={3} sx={{ padding: 2, maxWidth: 300 }}>
      <Box display="flex" alignItems="center" mb={2}>
        <Avatar src={user.picture} alt={user.name} sx={{ width: 56, height: 56 }} />
        <Box ml={2}>
          <Typography variant="h6">{user.name}</Typography>
          <Typography variant="body2" color="textSecondary">{user.email}</Typography>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={() => logout({ returnTo: window.location.origin } as LogoutOptions)}
      >
        Logout
      </Button>
    </Paper>
  );
};

export default ProfileDetails;