// CharacterEditor.tsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    TextField,
    Button,
    Switch,
    FormControlLabel,
    Chip,
    Box,
    Paper
} from '@mui/material';
import { Character, CharacterUpdate, fetchCharacter, createCharacter, updateCharacter } from '../../services/character_api';
import { useAuth0 } from '@auth0/auth0-react';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '20px',
        padding: '20px',
        maxWidth: '800px',
        margin: '0 auto',
        height: '100%',
        overflow: 'auto'
    },
    form: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '16px'
    },
    imagePreview: {
        width: '200px',
        height: '200px',
        objectFit: 'cover' as const,
        borderRadius: '4px'
    },
    avatarContainer: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'flex-start',
        gap: '10px'
    },
    tagsContainer: {
        display: 'flex',
        flexWrap: 'wrap' as const,
        gap: '8px',
        marginTop: '8px'
    },
    chip: {
        margin: '4px'
    },
    buttonContainer: {
        display: 'flex',
        gap: '12px',
        marginTop: '20px'
    },
    button: {
        minWidth: '120px'
    }
};

const CharacterEditor: React.FC = () => {
    const { characterId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [tagInput, setTagInput] = useState('');
    const { user, getAccessTokenSilently } = useAuth0();

    const [character, setCharacter] = useState<CharacterUpdate>({
        name: '',
        description: '',
        first_message: '',
        knowledge: '',
        system_prompt: '',
        physical_description: '',
        tags: [],
        is_nsfw: false
    });

    useEffect(() => {
        const loadCharacter = async () => {
            if (characterId) {
                setLoading(true);
                const token = await getAccessTokenSilently();
                try {
                    const data = await fetchCharacter(characterId, token);
                    setCharacter(data);
                } catch (error) {
                    console.error('Error loading character:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        loadCharacter();
    }, [characterId]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        try {
            const token = await getAccessTokenSilently();
            if (characterId) {
                await updateCharacter(characterId, character, token);
            } else {
                await createCharacter(character, token);
            }
            navigate('/characters');
        } catch (error) {
            console.error('Error saving character:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (field: keyof CharacterUpdate) => (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setCharacter((prev: any) => ({
            ...prev,
            [field]: e.target.value
        }));
    };

    const handleTagAdd = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && tagInput.trim()) {
            setCharacter((prev: any) => ({
                ...prev,
                tags: [...(prev.tags || []), tagInput.trim()]
            }));
            setTagInput('');
        }
    };

    const handleTagDelete = (tagToDelete: string) => {
        setCharacter((prev: any) => ({
            ...prev,
            tags: prev.tags?.filter((tag: any) => tag !== tagToDelete)
        }));
    };

    if (loading) {
        return <div style={{ textAlign: 'center', padding: '20px' }}>Loading...</div>;
    }

    return (
        <div style={styles.container}>
            <h1>{characterId ? 'Edit Character' : 'Create New Character'}</h1>

            <form onSubmit={handleSubmit} style={styles.form}>
                <TextField
                    label="Name"
                    value={character.name}
                    onChange={handleChange('name')}
                    required
                    fullWidth
                />

                <TextField
                    label="Description"
                    value={character.description}
                    onChange={handleChange('description')}
                    multiline
                    rows={3}
                    fullWidth
                />

                <TextField
                    label="First Message"
                    value={character.first_message}
                    onChange={handleChange('first_message')}
                    multiline
                    rows={2}
                    fullWidth
                />

                <TextField
                    label="Knowledge"
                    value={character.knowledge}
                    onChange={handleChange('knowledge')}
                    multiline
                    rows={4}
                    fullWidth
                />

                <TextField
                    label="System Prompt"
                    value={character.system_prompt}
                    onChange={handleChange('system_prompt')}
                    multiline
                    rows={4}
                    fullWidth
                />

                <TextField
                    label="Physical Description"
                    value={character.physical_description}
                    onChange={handleChange('physical_description')}
                    multiline
                    rows={3}
                    fullWidth
                />

                <div>
                    <TextField
                        label="Add Tags"
                        value={tagInput}
                        onChange={(e) => setTagInput(e.target.value)}
                        onKeyPress={handleTagAdd}
                        fullWidth
                    />
                    <div style={styles.tagsContainer}>
                        {character.tags?.map((tag: any) => (
                            <Chip
                                key={tag}
                                label={tag}
                                onDelete={() => handleTagDelete(tag)}
                                style={styles.chip}
                            />
                        ))}
                    </div>
                </div>

                <FormControlLabel
                    control={
                        <Switch
                            checked={character.is_nsfw}
                            onChange={(e) => setCharacter((prev: CharacterUpdate) => ({
                                ...prev,
                                is_nsfw: e.target.checked
                            }))}
                        />
                    }
                    label="NSFW Content"
                />

                <div style={styles.buttonContainer}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={styles.button}
                        disabled={loading}
                    >
                        {characterId ? 'Update' : 'Create'}
                    </Button>

                    <Button
                        variant="outlined"
                        onClick={() => navigate('/characters')}
                        style={styles.button}
                    >
                        Cancel
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default CharacterEditor;