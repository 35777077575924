import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { ThemeProvider, createTheme, PaletteMode } from '@mui/material';
import { CssBaseline } from '@mui/material';

interface ColorModeContextType {
  toggleColorMode: () => void;
  mode: PaletteMode;
}

const ColorModeContext = createContext<ColorModeContextType>({
  toggleColorMode: () => { },
  mode: 'light',
});

export const useColorMode = () => useContext(ColorModeContext);

interface ColorModeProviderProps {
  children: ReactNode;
}

export const ColorModeProvider: React.FC<ColorModeProviderProps> = ({ children }) => {
  const [mode, setMode] = useState<PaletteMode>(() => {
    const savedMode = localStorage.getItem('colorMode');
    return (savedMode as PaletteMode) || 'light';
  });

  const colorMode = {
    toggleColorMode: () => {
      setMode((prevMode) => {
        const newMode = prevMode === 'light' ? 'dark' : 'light';
        localStorage.setItem('colorMode', newMode);
        return newMode;
      });
    },
    mode,
  };

  useEffect(() => {
    const savedMode = localStorage.getItem('colorMode');
    if (savedMode) {
      setMode(savedMode as PaletteMode);
    }
  }, []);

  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#1976d2',
      },
      background: {
        default: '#f0f0f0',
        paper: '#ffffff',
      },
      text: {
        primary: '#000000',
        secondary: '#555555',
      },
    },
  });


  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#FF6B6B', // Retro coral/orange
        light: '#FF9E9E',
        dark: '#CC4545',
      },
      secondary: {
        main: '#4ECDC4', // Mint/cyan accent
        light: '#7EDCD6',
        dark: '#2E9E97',
      },
      background: {
        default: '#121212',
        paper: '#1A1A1A',
      },
      text: {
        primary: '#FFFFFF',
        secondary: 'rgba(255, 255, 255, 0.7)',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
    },
    typography: {
      fontFamily: '"Space Mono", "Roboto Mono", monospace',
      h1: {
        fontFamily: '"Space Grotesk", "Roboto", sans-serif',
        fontWeight: 700,
        letterSpacing: '0.02em',
      },
      h2: {
        fontFamily: '"Space Grotesk", "Roboto", sans-serif',
        fontWeight: 700,
        letterSpacing: '0.01em',
      },
      h3: {
        fontFamily: '"Space Grotesk", "Roboto", sans-serif',
        fontWeight: 600,
      },
      body1: {
        fontFamily: '"Space Mono", "Roboto Mono", monospace',
        letterSpacing: '0.03em',
      },
      button: {
        fontFamily: '"Space Mono", "Roboto Mono", monospace',
        fontWeight: 700,
        letterSpacing: '0.1em',
      },
    },
    shape: {
      borderRadius: 0, // Sharp corners for brutalist style
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            textTransform: 'uppercase',
            padding: '12px 24px',
            border: '2px solid',
            '&:hover': {
              borderWidth: '2px',
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            border: '1px solid rgba(255, 255, 255, 0.12)',
            backgroundImage: 'none',
            '&:hover': {
              border: '1px solid rgba(255, 255, 255, 0.3)',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
            '&::before': {
              display: 'none',
            },
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#121212',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#333333',
              borderRadius: 0,
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#444444',
            },
          },
        },
      },
    },
    spacing: 8,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });

  const theme = mode === 'light' ? lightTheme : darkTheme;

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};