import React from 'react';
import { Box, CircularProgress } from '@mui/material';

interface LoadingSpinnerProps {
  styles: {
    loadingContainer: object;
  };
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ styles }) => (
  <Box sx={styles.loadingContainer}>
    <CircularProgress />
  </Box>
);

export default LoadingSpinner;