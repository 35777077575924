import React, { useCallback, useEffect, useState } from 'react';
import { 
    Avatar, 
    Box, 
    Card, 
    CardContent, 
    Typography, 
    IconButton, 
    Divider,
    Tooltip,
    Badge
} from '@mui/material';
import { 
    AccessTime as AccessTimeIcon,
    Delete as DeleteIcon,
    Star as StarIcon,
    StarBorder as StarBorderIcon,
    Message as MessageIcon
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Chat, fetchChats } from '../../services/api';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingSpinner from '../ChatComponent/LoadingSpinner';
import { useColorMode } from '../../ColorModeContext';
import { formatDistanceToNow } from 'date-fns';

export default function ChatList() {
    const MemoizedLoadingSpinner = React.memo(LoadingSpinner);
    const [chats, setChats] = useState<Chat[]>([]);
    const [favoriteChats, setFavoriteChats] = useState<Set<string>>(new Set());
    const [isLoading, setIsLoading] = useState(false);
    const { user, getAccessTokenSilently } = useAuth0();
    const { mode } = useColorMode();
    const styles = getStyles(mode);

    const loadChats = useCallback(async () => {
        try {
            setIsLoading(true);
            const token = await getAccessTokenSilently();
            const response: Chat[] = await fetchChats(token);
            setChats(response);
            console.log("Chats:", response);
        } catch (error) {
            console.error('Error fetching chats:', error);
        } finally {
            setIsLoading(false);
        }
    }, [getAccessTokenSilently]);

    const toggleFavorite = (chatId: string) => {
        setFavoriteChats(prev => {
            const newSet = new Set(prev);
            if (newSet.has(chatId)) {
                newSet.delete(chatId);
            } else {
                newSet.add(chatId);
            }
            return newSet;
        });
    };

    useEffect(() => {
        loadChats();
    }, [loadChats]);

    if (isLoading) {
        return <MemoizedLoadingSpinner styles={styles} />;
    }

    return (
        <Box sx={styles.container}>
            <Typography variant="h5" sx={styles.header}>
                Recent Conversations
            </Typography>
            <Box sx={styles.chatList}>
                {chats.map((chat) => (
                    <Card key={uuidv4()} sx={styles.chatCard}>
                        <CardContent sx={styles.cardContent}>
                            <Box sx={styles.contentSection}>
                                <Box sx={styles.headerSection}>
                                    <Typography variant="h6" component={Link} to={`/chat/${chat.chat_id}`} sx={styles.title}>
                                        {chat.title || "No Title"}
                                    </Typography>
                                    <Box sx={styles.actionButtons}>
                                        <Tooltip title="Last message">
                                            <Box sx={styles.timeStamp}>
                                                <AccessTimeIcon sx={styles.icon} />
                                                <Typography variant="caption">
                                                    TIME{/* {formatDistanceToNow(new Date(chat.messages[chat.messages.length-1].timestamp), { addSuffix: true })} */}
                                                </Typography>
                                            </Box>
                                        </Tooltip>
                                        <Tooltip title="Message count">
                                            <Box sx={styles.messageCount}>
                                                <MessageIcon sx={styles.icon} />
                                                <Typography variant="caption">
                                                    {chat.messages.length || 0}
                                                </Typography>
                                            </Box>
                                        </Tooltip>
                                        <IconButton size="small">
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                                <Typography sx={styles.description}>
                                    {chat.messages[chat.messages.length-1].content || 'No messages yet'}
                                </Typography>
                            </Box>
                        </CardContent>
                        <Divider />
                    </Card>
                ))}
            </Box>
        </Box>
    );
}

function getStyles(mode: string) {
    return {
        container: {
            width: '100%',
            maxWidth: '900px',
            margin: '0 auto',
            padding: '20px',
        },
        header: {
            mb: 3,
            fontWeight: 'bold',
        },
        chatList: {
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
        },
        chatCard: {
            width: '100%',
            transition: 'transform 0.2s, box-shadow 0.2s',
            '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: 3,
            },
            backgroundColor: mode === 'light' ? '#ffffff' : '#2d2d2d',
        },
        cardContent: {
            display: 'flex',
            padding: '16px !important',
            gap: 2,
        },
        avatarSection: {
            position: 'relative',
        },
        avatar: {
            width: 50,
            height: 50,
            backgroundColor: mode === 'light' ? '#f0f0f0' : '#3d3d3d',
        },
        badge: {
            position: 'absolute',
            bottom: 2,
            right: 2,
        },
        contentSection: {
            flex: 1,
            width: '100%', // Add this
            minWidth: 0,   // Add this to allow text truncation
        },
        headerSection: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start', // Changed from 'center'
            mb: 1,
            gap: 2, // Add this to maintain space between title and actions
        },
        title: {
            color: mode === 'light' ? '#1a1a1a' : '#ffffff',
            textDecoration: 'none',
            flex: 1,      // Add this
            minWidth: 0,  // Add this
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        description: {
            color: mode === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.7)',
            fontSize: '0.875rem',
            display: '-webkit-box',
            '-webkit-line-clamp': 3,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal', // Changed from 'nowrap'
            lineHeight: '1.5em',
            maxHeight: '4.5em', // 3 lines × 1.5em line-height
        },
        actionButtons: {
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            flexShrink: 0, // Add this to prevent shrinking
        },
        timeStamp: {
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
        },
        messageCount: {
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
        },
        icon: {
            fontSize: '1rem',
        },
        starIcon: {
            color: '#ffc107',
        },
        loadingContainer: {

        }
    };
}