'use client'

import React, { useState, useEffect, CSSProperties } from 'react'
import { useWebSocket } from '../../App'
import { Box, Typography, Skeleton } from '@mui/material'

interface LatestImageDisplayProps {
  placeholderImage: string
}

const styles: { [key: string]: CSSProperties } = {
    container: {
        width: '100%',
        height: '100%',
        perspective: '1000px',
        backgroundColor: '#161616',
        borderRadius: '30px',
    },
    flipper: {
        width: '100%',
        height: '100%',
        position: 'relative',
        transition: 'transform 0.6s',
        transformStyle: 'preserve-3d',
    },
    flipped: {
        transform: 'rotateY(180deg)',
    },
    imageFront: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        backfaceVisibility: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        // backgroundColor: '#ffffff',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    imageBack: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        backfaceVisibility: 'hidden',
        transform: 'rotateY(180deg)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        overflowY: 'scroll',
        scrollbarWidth: 'thin',
        scrollbarColor: '#888 #f1f1f1'
    },
    displayImage: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
        borderRadius: '8px',
        transition: 'opacity 0.5s ease',
    },
    imageLoading: {
        opacity: 0,
    },
    imageLoaded: {
        opacity: 1,
    },
    caption: {
        padding: '2em',
        textAlign: 'justify',
        color: '#666',
    },
}

// Add global styles for scrollbar customization
const globalStyles = `
    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`

// Inject global styles into the document
const styleSheet = document.createElement("style")
styleSheet.type = "text/css"
styleSheet.innerText = globalStyles
document.head.appendChild(styleSheet)

export default function LatestImageDisplay({ placeholderImage }: LatestImageDisplayProps) {
  const [latestImage, setLatestImage] = useState<string | null>(null)
  const [caption, setCaption] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)
  const [isFlipped, setIsFlipped] = useState(false)
  const { subscribe, unsubscribe } = useWebSocket()

  useEffect(() => {
    const handleImageMessage = (payload: any) => {
      setLoading(true)
      if (payload.image_url) {
        setLatestImage(payload.image_url)
      } else if (payload.image_filename) {
        const baseUrl = process.env.REACT_APP_API_URL || ''
        const imageUrl = `${baseUrl}${payload.image_filename.replace("data/", "/")}`
        setLatestImage(imageUrl)
      }
      setCaption(payload.caption || '')
    }

    subscribe('image', handleImageMessage)
    return () => unsubscribe('image')
  }, [subscribe, unsubscribe])

  const handleImageLoad = () => {
    setLoading(false)
  }

  const handleFlip = () => {
    setIsFlipped(!isFlipped)
  }

  return (
    <Box style={{...styles.container, backgroundColor: latestImage ? "transparent" : styles.container.backgroundColor}}>
      <div 
        style={{
          ...styles.flipper,
          ...(isFlipped ? styles.flipped : {}),
        }}
        onClick={handleFlip}
      >
        <div style={styles.imageFront}>
          {/* {loading && (
            <Skeleton variant="rectangular" width="100%" height="100%" sx={{borderRadius:"30px"}}/>
          )} */}
          {latestImage && (
            <img
              src={latestImage}
              alt="Latest"
              onLoad={handleImageLoad}
              style={{
                ...styles.displayImage,
                ...(loading ? styles.imageLoading : styles.imageLoaded),
              }}
            />
          )}
        </div>
        <div style={styles.imageBack}>
          {caption && (
            <Box style={styles.caption}>
              {caption}
            </Box>
          )}
        </div>
      </div>
    </Box>
  )
}