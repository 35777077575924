// src/styles/effects.ts
import { styled, keyframes } from '@mui/material/styles';
import { ComponentType, ElementType, JSXElementConstructor } from 'react';

const fluid = keyframes`
  0% {
    background-position: 0% 0%;
    transform: scale(1);
  }
  20% {
    background-position: 50% 25%;
    transform: scale(1.02) skew(1deg);
  }
  40% {
    background-position: 100% 50%;
    transform: scale(1) skew(-1deg);
  }
  60% {
    background-position: 75% 75%;
    transform: scale(0.98) skew(0deg);
  }
  80% {
    background-position: 25% 100%;
    transform: scale(1.02) skew(-1deg);
  }
  100% {
    background-position: 0% 0%;
    transform: scale(1) skew(0deg);
  }
`;

// Alternative version with more organic movement
const fluidOrganic = keyframes`
  0% {
    background-position: 0% 0%;
    transform: translate(0, 0) rotate(0deg) scale(1);
  }
  25% {
    background-position: 50% 25%;
    transform: translate(2px, -2px) rotate(0.5deg) scale(1.03);
  }
  50% {
    background-position: 100% 75%;
    transform: translate(-1px, 2px) rotate(-0.5deg) scale(0.98);
  }
  75% {
    background-position: 50% 100%;
    transform: translate(-2px, -1px) rotate(0.25deg) scale(1.02);
  }
  100% {
    background-position: 0% 0%;
    transform: translate(0, 0) rotate(0deg) scale(1);
  }
`;

// Cyberpunk-style glitch version
const fluidGlitch = keyframes`
  0% {
    background-position: 0% 0%;
    transform: translate(0, 0) scale(1);
    filter: hue-rotate(0deg);
  }
  15% {
    background-position: 20% 30%;
    transform: translate(3px, -2px) scale(1.02);
    filter: hue-rotate(45deg);
  }
  30% {
    background-position: 50% 50%;
    transform: translate(-2px, 1px) scale(0.99);
    filter: hue-rotate(90deg);
  }
  45% {
    background-position: 80% 60%;
    transform: translate(1px, 3px) scale(1.01);
    filter: hue-rotate(180deg);
  }
  60% {
    background-position: 100% 100%;
    transform: translate(-3px, -1px) scale(1.03);
    filter: hue-rotate(270deg);
  }
  75% {
    background-position: 70% 50%;
    transform: translate(2px, 2px) scale(0.98);
    filter: hue-rotate(180deg);
  }
  90% {
    background-position: 30% 20%;
    transform: translate(-1px, -3px) scale(1.02);
    filter: hue-rotate(90deg);
  }
  100% {
    background-position: 0% 0%;
    transform: translate(0, 0) scale(1);
    filter: hue-rotate(0deg);
  }
`;

// Wave-like movement
const fluidWave = keyframes`
  0% {
    background-position: 0% 0%;
    transform: translateY(0) scaleY(1);
  }
  25% {
    background-position: 25% 50%;
    transform: translateY(-5px) scaleY(1.02);
  }
  50% {
    background-position: 50% 100%;
    transform: translateY(0) scaleY(0.98);
  }
  75% {
    background-position: 75% 50%;
    transform: translateY(5px) scaleY(1.02);
  }
  100% {
    background-position: 0% 0%;
    transform: translateY(0) scaleY(1);
  }
`;

export const withHologram = (Component: JSXElementConstructor<any>) => 
  styled(Component)`
    position: relative;
    background-image: url('/img/rainbow.png');
    background-size: 800% 800%;
    animation: ${fluidOrganic} 20s ease-in-out infinite;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  `;

export const withHologramBG = <T extends React.JSXElementConstructor<any>>(Component: T) => 
  styled(Component)`
    position: relative;
    background-image: url('/img/rainbow.png');
    background-size: 2400% 2400%;
    animation: ${fluid} 360s ease-in-out infinite;
    padding: 16px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    color: white;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.15) 0%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 100%
      );
      background-size: 1000% 1000%;
      animation: ${fluid} 50s linear infinite;
      pointer-events: none;
    }

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.47);
    }
  `;