import axios from 'axios';
import { Project, ProjectDetail } from '../types/project';

const API_URL = process.env.REACT_APP_API_URL;

export interface Chat {
  chat_id: string;
  user_id: string;
  title?: string;
  description?: string;
  is_ephemeral: boolean;
  messages: any[];
  last_updated: number;
}

export const fetchChats = async (token: string): Promise<Chat[]> => {
  try {
    const response = await axios.get(`${API_URL}/chats`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error;
  }
};

export interface Character {
  character_id: string;
  name: string;
  description: string;
  avatar_url: string;
  tags?: string[];
  created_at: string;
  updated_at: string;
  is_nsfw: boolean;
}

export interface PaginatedResponse<T> {
  items: T[];
  count: number;
  last_evaluated_key?: Record<string, any>;
}

export const fetchPublicCharacters = async (
  lastEvaluatedKey?: string,
  allowNsfw: boolean = true
): Promise<PaginatedResponse<Character>> => {
  try {
    const queryParams = new URLSearchParams({
      allow_nsfw: allowNsfw.toString()
    });
    
    if (lastEvaluatedKey) {
      queryParams.append('last_evaluated_key', lastEvaluatedKey);
    }

    const response = await axios.get<PaginatedResponse<Character>>(
      `${API_URL}/characters?${queryParams.toString()}`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    
    return response.data;
  } catch (error) {
    console.error('Error fetching characters:', error);
    throw error;
  }
};

export const createProject = async (project: Project, token: string): Promise<Project> => {
  try {
    const response = await axios.post(`${API_URL}/projects`, project, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating project:', error);
    throw error;
  }
}

export const fetchProjectDetail = async (projectId: string, token: string): Promise<ProjectDetail> => {
  try {
    const response = await axios.get(`${API_URL}/projects/${projectId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching project detail for project ID ${projectId}:`, error);
    throw error;
  }
};

export const createConversation = async (project_id: string, token: string): Promise<{ chat_id: string }> => {
  try {
    const response = await axios.post(`${API_URL}/chats`, { project_id: project_id }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error creating conversation for project ID ${project_id}:`, error);
    throw error;
  }
};

export const getConversation = async (project_id: string, chat_id: string, token: string): Promise<any> => {
  try {
    const response = await axios.get(`${API_URL}/chats/${chat_id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error getting chat for project ID ${project_id} and chat ID ${chat_id}:`, error);
    throw error;
  }
};

export const getOrCreateUser = async (user: any, token: string): Promise<any> => {
  try {
    const response = await axios.post(`${API_URL}/users`, user, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error getting/creating user for:`, user, error);
    throw error;
  }
};

export const fetchProfile = async (user: any, token: string): Promise<any> => {
  try {
    const response = await axios.get(`${API_URL}/profile`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error getting/creating user for:`, user, error);
    throw error;
  }
};