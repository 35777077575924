import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    TextField,
    Button,
    Switch,
    FormControlLabel,
    Chip,
    Box,
    Paper,
    Typography
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchProfile } from '../../services/api';

const styles = {
   container: {
         display: 'flex',
         flexDirection: 'column' as const,
         gap: '20px',
         padding: '20px',
         maxWidth: '800px',
         margin: '0 auto',
         height: '100%',
         overflow: 'auto'
   }
};

const Profile: React.FC = () => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState<any>(null);

    useEffect(() => {
        const loadProfile = async () => {
            try {
                const token = await getAccessTokenSilently();
                const profileData = await fetchProfile(user, token);
                setProfile(profileData);
            } catch (error) {
                console.error('Error fetching profile:', error);
            } finally {
                setLoading(false);
            }
        };

        if (isAuthenticated) {
            loadProfile();
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    if (loading) {
        return <div style={{ textAlign: 'center', padding: '20px' }}>Loading...</div>;
    }

    if (!profile) {
        return <div style={{ textAlign: 'center', padding: '20px' }}>No profile data available.</div>;
    }

    return (
        <div style={styles.container}>
            <h1>Profile</h1>
            <Typography variant="h6">User Info</Typography>
            <Typography>Name: {user?.name}</Typography>
            <Typography>Email: {user?.email}</Typography>

            <Typography variant="h6" style={{ marginTop: '20px' }}>Profile Info</Typography>
            <Typography>Preferred Name: {profile.preferred_name || 'N/A'}</Typography>
            <Typography>Physical Description: {profile.physical_description || 'N/A'}</Typography>
            <Typography>Memories: {profile.memories || 'N/A'}</Typography>
            <Typography>User Preferences: {profile.user_preferences || 'N/A'}</Typography>
            <Typography>Dossier: {profile.dossier || 'N/A'}</Typography>
            <Typography>Age: {profile.age || 'N/A'}</Typography>
            <Typography>System Prompt: {profile.system_prompt || 'N/A'}</Typography>
            <Typography>Additional Instructions: {profile.additional_instructions || 'N/A'}</Typography>
            <Typography>Created At: {profile.created_at}</Typography>
            <Typography>Updated At: {profile.updated_at}</Typography>
        </div>
    );
};

export default Profile;