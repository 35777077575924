import ReconnectingWebSocket, { ErrorEvent } from 'reconnecting-websocket';

// Define WebSocket message types
interface WebSocketMessage {
  type: string;
  data: any;
}

export interface WebSocketContextType {
  sendMessage: (type: string, data: any) => void;
  subscribe: (type: string, handler: (data: any) => void) => void;
  unsubscribe: (type: string) => void;
}

let ws: ReconnectingWebSocket | null = null;
const handlers: { [type: string]: ((data: any) => void)[] } = {};

export const connectSocket = async (getAccessTokenSilently: () => Promise<string>): Promise<void> => {
  const token = await getAccessTokenSilently();

  const wsUrl = `${process.env.REACT_APP_WS_URL}?token=${token}`;
  ws = new ReconnectingWebSocket(wsUrl);

  ws.onopen = () => {
    console.log('Connected to WebSocket server');
  };

  ws.onclose = () => {
    console.log('Disconnected from WebSocket server');
  };

  ws.onmessage = (event: MessageEvent) => {
    const message: WebSocketMessage = JSON.parse(event.data);
    console.log(message);
    handleMessage(message);
  };

  ws.onerror = (error: ErrorEvent) => {
    console.error('WebSocket error:', error);
  };
};

export const disconnectSocket = (): void => {
  if (ws) {
    ws.close();
    ws = null;
  }
};

export const sendMessage = (type: string, data: any): void => {
  if (ws && ws.readyState === WebSocket.OPEN) {
    const message: WebSocketMessage = { type, data };
    ws.send(JSON.stringify(message));
  }
};

export const subscribe = (type: string, handler: (data: any) => void): void => {
  if (!handlers[type]) {
    handlers[type] = [];
  }
  handlers[type].push(handler);
};

export const unsubscribe = (type: string): void => {
  if (handlers[type]) {
    delete handlers[type];
  }
};

const handleMessage = (message: WebSocketMessage): void => {
  const { type, data } = message;
  if (handlers[type]) {
    handlers[type].forEach(handler => handler(data));
  }
};