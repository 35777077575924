import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    TextField,
    Button,
    Switch,
    FormControlLabel,
    Chip,
    Box,
    Paper
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

const styles = {
   container: {
         display: 'flex',
         flexDirection: 'column' as const,
         gap: '20px',
         padding: '20px',
         maxWidth: '800px',
         margin: '0 auto',
         height: '100%',
         overflow: 'auto'
   }
};

const EditProfile: React.FC = () => {
    const [loading, setLoading] = useState(false);

    if (loading) {
        return <div style={{ textAlign: 'center', padding: '20px' }}>Loading...</div>;
    }

    return (
        <div style={styles.container}>
            <h1>Edit Profile</h1>
        </div>
    );
};

export default EditProfile;