import React from 'react';
import { Box } from '@mui/material';
import { Message } from './ChatInterfaces';
import { v4 as uuidv4 } from 'uuid';
import MemoizedTextBubble from './TextBubble';

interface ChatBubblesProps {
  messages: Message[];
  messagesEndRef: React.RefObject<HTMLDivElement>;
  style?: React.CSSProperties;
}

const ChatBubbles: React.FC<ChatBubblesProps> = ({ messages, messagesEndRef, style }) => {
  return (
    <Box sx={{ flexGrow: 1, overflowY: 'auto' }} style={style}>
      {messages.map((message) => (
        <MemoizedTextBubble
          key={message.message_id + uuidv4()}
          role={message.role}
          message_id={message.message_id}
          content={message.content || ''}
          timestamp={message.timestamp}
          image={message.image!}
          username={message.username!}
        />
      ))}
      <div ref={messagesEndRef} />
    </Box>
  );
};

export default ChatBubbles;