import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { SxProps, Theme } from '@mui/material/styles';
import { Button } from "@mui/material";

interface LoginButtonProps {
  style?: React.CSSProperties;
  className?: string;
  sx?: SxProps<Theme>;
  // Add any other props you might want to accept
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

const LoginButton: React.FC<LoginButtonProps> = ({ 
  style, 
  className, 
  sx,
  variant = 'contained',
  color = 'primary',
  ...props 
}) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      variant={variant}
      color={color}
      onClick={() => loginWithRedirect()}
      style={style}
      className={className}
      sx={sx}
      {...props}
    >
      Login / SignUp
    </Button>
  );
};

export default LoginButton;